import { useContext, useEffect, useRef, useState } from "react";
import { Col, Spinner, Alert } from "react-bootstrap";
import GithubIcon from "../../../assets/iconfonts/bootstrap-icons/icons/icons/github.svg";
import { getJobId, getProjectById, getProjectsForUser } from "../../../network/ApiAxios";
import axios from "axios";
import { DeployProgressProps } from "../../../models/NewProjectFlowModels";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../shade/layouts/App";
import { getEmbedProject } from "../../../utils";

const DeployProgress: React.FC<DeployProgressProps> = ({ githubRepositoryId, projectName, jobID }) => {
  const navigate = useNavigate();
  const [jobId, setJobId] = useState<string>();
  const [deployProgress, setDeployProgress] = useState<any>();

  const { setProjectCodeArchive, setProjectKey } = useContext<any>(AppContext);

  const API_BASE_URL = process.env.REACT_APP_BUILD_API_BASE_URL;

  const isGettingProjectCode = useRef(false);

  const fetchJobId = async () => {
    if (jobID) {
      setJobId(jobID);
      return;
    }
    const res: any = await getJobId(githubRepositoryId);
    if (res.status === 200 && res.data.status === "ok") {
      setJobId(res.data.jobId);
    }
  };

  const fetchDeployProgress = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/state/${jobId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
          "Content-Type": "application/json",
        },
        params: {
          tokenName: "Auto Generated Token By Browser Getting Started - " + new Date().toISOString(),
        },
      });

      if (response.data.BuildStatus === "SUCCEEDED") {
        // get the project id
        const resProjects = await getProjectsForUser(0, Number.MAX_SAFE_INTEGER);
        const project = resProjects.data?.projects.find((project: { name: any }) => project.name === projectName);

        const res: any = await getProjectById(project.id ?? "");
        const localActiveEnv = res.data.project.projectEnvs.find((env: any) => env.name === "prod");

        navigate(`/edit-code/${project.id}/${localActiveEnv.id}?showModalURLs=true`);
      }

      setDeployProgress(response.data);
      if (response.data.BuildStatus === "FAILED") {
        return false;
      }

      if (
        response.data.Transitions.find((transition: any) => transition.From === "CREATING_EMPTY_PROJECT") &&
        !isGettingProjectCode.current
      ) {
        const resProjects = await getProjectsForUser(0, Number.MAX_SAFE_INTEGER);
        const project = resProjects.data?.projects.find((project: { name: any }) => project.name === projectName);

        const res: any = await getProjectById(project.id ?? "");
        const localActiveEnv = res.data.project.projectEnvs.find((env: any) => env.name === "prod");

        setProjectKey(`${project.id}_${localActiveEnv.id}`);
        setProjectCodeArchive(getEmbedProject(res.data.project.name, res.data.project.region, localActiveEnv.name));
        isGettingProjectCode.current = true;
      }
    } catch (error) {
      console.error("Error fetching deployment progress:", error);
    }
  };

  useEffect(() => {
    if (!jobId) {
      const intervalId = setInterval(() => {
        fetchJobId();
      }, 1000);
      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [jobId]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const res = await fetchDeployProgress();
      if (res === false) {
        clearInterval(intervalId);
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [jobId]);

  const capitalize = (s: string) => {
    const words = s.split("_");
    return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ");
  };

  return (
    <>
      <Col lg={1}></Col>
      <Col className="border rounded p-4" lg={8}>
        <div className="p-0 w-100">
          {/* Card header */}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              <h4 className="mb-2 fw-bold">Deploying your project {projectName}</h4>
              <p className="m-0 text-muted">Please wait while we deploy your project. It might take a few minutes.</p>
            </div>

            <div>
              <img src={GithubIcon} alt="github_icon" style={{ width: "63px" }} className="invert-dark-theme" />
            </div>
          </div>

          {/* Deploy progress */}
          <div className="m-auto d-flex flex-column align-items-center border rounded p-3">
            {/* Loading alert until we have output from the build machine */}
            {(!deployProgress || deployProgress?.Transitions.length < 1) && (
              <Alert variant="warning w-100">
                <p className="text-muted m-0">Preparing the deployment...</p>
              </Alert>
            )}

            {/* Handle errors */}
            {deployProgress?.Transitions?.find((transition: any) => transition?.To === "FAILED")?.To === "FAILED" && (
              <Alert variant="danger w-100">
                <p className="m-0">{deployProgress?.Transitions?.find((item: any) => item.Stderr !== "")?.Stderr}</p>
              </Alert>
            )}

            {deployProgress?.Transitions?.map((progress: any, index: number) => (
              <div className="d-flex justify-content-between align-items-center w-100 mb-3">
                <h6 className="m-0 fw-semibold">{capitalize(progress?.To)}</h6>

                {index === deployProgress.Transitions.length - 1 && progress?.To !== "FAILED" ? (
                  <Spinner variant="primary" animation="border" size="sm" />
                ) : (
                  <>
                    {progress?.To === "FAILED" ? (
                      <img src={require("../../../assets/img/svgs/wrong_mark.svg").default} alt="integrationStatus" />
                    ) : (
                      <img src={require("../../../assets/img/svgs/check_mark.svg").default} alt="integrationStatus" />
                    )}
                  </>
                )}
              </div>
            ))}

            {/* {deployProgress?.BuildStatus !== "SUCCEEDED" &&
            deployProgress?.BuildStatus !== "FAILED" &&
            deployProgress?.Transitions.length > 0 ? (
              <Spinner variant="primary" animation="border" />
            ) : (
              ""
            )} */}
          </div>
        </div>
      </Col>
    </>
  );
};

export default DeployProgress;
