import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { getCheckoutLink } from "../../network/ApiAxios";

const Auth = () => {
  const [loading, setLoading] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const redirect: string = urlParams.get("redirect")?.toString() || "";

  let navigate = useNavigate();

  React.useEffect(() => {
    const handleRedirect = async () => {
      if (localStorage?.getItem("apiToken")) {
        if (redirect === "express-getting-started") {
          navigate("/express-getting-started", { replace: true });
          return;
        }
        if (redirect === "new-project") {
          navigate("/new-project", { replace: true });
          return;
        }
        if (redirect === "html-getting-started") {
          navigate("/html-getting-started", { replace: true });
          return;
        }
        if (redirect === "angular-getting-started") {
          navigate("/angular-getting-started", { replace: true });
          return;
        }
        if (redirect === "react-getting-started") {
          navigate("/react-getting-started", { replace: true });
          return;
        }
        if (redirect === "faas-getting-started") {
          navigate("/faas-getting-started", { replace: true });
          return;
        }
        if (redirect === "vue-getting-started") {
          navigate("/vue-getting-started", { replace: true });
          return;
        }
        if (redirect === "fastify-getting-started") {
          navigate("/fastify-getting-started", { replace: true });
          return;
        }
        if (redirect === "nuxt-getting-started") {
          navigate("/nuxt-getting-started", { replace: true });
          return;
        }
        if (redirect === "nitro-getting-started") {
          navigate("/nitro-getting-started", { replace: true });
          return;
        }
        if (redirect === "express-postgresql-getting-started") {
          navigate("/express-postgresql-getting-started", { replace: true });
          return;
        }
        if (redirect === "express-mongo-getting-started") {
          navigate("/express-mongo-getting-started", { replace: true });
          return;
        }
        if (redirect === "express-postgres-auth-starter") {
          navigate("/express-postgres-auth-starter", { replace: true });
          return;
        }
        if (redirect === "upgrade-now-pro") {
          window.location.href = (await getCheckoutLink("Pro")).data.checkoutUrl;
          return;
        }
        navigate("/dashboard", { replace: true });
        return;
      }

      setLoading(false);
    };

    handleRedirect();
  }, [navigate, redirect]);

  if (loading) {
    return null;
  }

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default Auth;
